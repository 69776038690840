// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cbe8XpYm,.viuJEHzH{position:relative}@media(hover:hover){.viuJEHzH:hover .pN3U4Lkm{opacity:1}}@media(hover:none){.viuJEHzH:active .pN3U4Lkm{opacity:1}}.Xee3MnBm{max-height:18.75rem;opacity:0;pointer-events:none}.rZNBqKdi{--rotate-y:0deg;left:var(--shift)}.WGPwrcmn{--rotate-y:180deg;right:var(--shift)}.WGPwrcmn,.rZNBqKdi{--icon-size:1.25rem;--shift:-0.75rem;border:1px solid var(--color-gray-3);padding:.13rem;position:absolute;top:50%;transform:translateY(-50%) rotateY(var(--rotate-y));transition:.1s linear;transition-property:opacity,visibility;z-index:3}@media(max-width:667.98px){.WGPwrcmn,.rZNBqKdi{display:none}}.pN3U4Lkm{background:var(--color-bg)!important;cursor:not-allowed!important;opacity:0}.pEKEmBjT{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}.a5VO1K9j{transform:scale(2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "Cbe8XpYm",
	"content": "viuJEHzH",
	"navDisabled": "pN3U4Lkm",
	"contentHidden": "Xee3MnBm",
	"navPrev": "rZNBqKdi",
	"navNext": "WGPwrcmn",
	"placeholder": "pEKEmBjT",
	"placeholderSpinner": "a5VO1K9j"
};
module.exports = ___CSS_LOADER_EXPORT___;
