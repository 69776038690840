// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FyJMaJY2{font-family:inherit;font-size:max(40px,min(8vw,100px));font-weight:400;letter-spacing:-.02em;line-height:95%;margin:0;max-width:85%;padding:0 0 30px;transition:font-size .15s;width:100%}@media(min-width:947.98px){.FyJMaJY2{padding:0 0 120px}}.FyJMaJY2 u{background-image:var(--gradient);background-position:0 100%;background-repeat:no-repeat;background-size:100% 4px;display:inline-block;-webkit-text-decoration:none;text-decoration:none;transition:background-size .15s;vertical-align:top}@media(min-width:947.98px){.FyJMaJY2 u{background-size:100% 8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"title": "FyJMaJY2"
};
module.exports = ___CSS_LOADER_EXPORT___;
