// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bBINvdwS{padding:0 0 40px}@media(min-width:667.98px){.bBINvdwS{padding:0 0 100px}}.CmSGWZcT{display:flex}@media(min-width:667.98px){.CmSGWZcT{display:none}}.TSx7SXw3{display:none}@media(min-width:667.98px){.TSx7SXw3{display:flex;padding:0 0 60px;position:relative}}.U64IDKvY{display:none}@media(min-width:667.98px){.U64IDKvY{bottom:0;display:block;left:0;opacity:.5;position:absolute;right:0;top:0}}.ZqP-7p1a{flex-basis:90vw}@media(min-width:667.98px){.ZqP-7p1a{flex-basis:50vw;padding:0 32px 0 0;transition:transform .35s}}@media(min-width:667.98px)and (hover:hover){.ZqP-7p1a:hover{transform:translateY(.5rem)}}@media(min-width:667.98px)and (hover:none){.ZqP-7p1a:active{transform:translateY(.5rem)}}@media(min-width:947.98px){.ZqP-7p1a{flex-basis:26vw}}@media(min-width:1229.98px){.ZqP-7p1a{padding:0 90px 0 0}}.eDErJzd8{color:var(--color-text);font-size:14px;opacity:.5;padding:0 0 16px}@media(min-width:667.98px){.eDErJzd8{font-size:24px}}.TQ2BrjIx{flex-grow:1;font-size:24px;font-weight:400;margin:0;padding:0 0 16px}@media(min-width:947.98px){.TQ2BrjIx{font-size:30px;padding:0 0 24px}}.SfUj-Whe{margin:0 0 40px}@media(min-width:947.98px){.auwHcqNN{flex-basis:48vw}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "bBINvdwS",
	"swiper": "CmSGWZcT",
	"news": "TSx7SXw3",
	"newsBackgrpound": "U64IDKvY",
	"item": "ZqP-7p1a",
	"itemTime": "eDErJzd8",
	"itemTitle": "TQ2BrjIx",
	"itemImage": "SfUj-Whe",
	"itemFirst": "auwHcqNN"
};
module.exports = ___CSS_LOADER_EXPORT___;
